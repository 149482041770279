

















































import { View } from 'client-website-ts-library/plugins';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class Seller extends Mixins(View) {
}
